import React, { useState, useEffect } from 'react';
import { string } from 'prop-types';
import root from 'window-or-global';
import { buildLogger, LoggableError } from 'logger';
import StackTrace from 'stacktrace-js';
import { LOYALTY_STAMP } from 'constants/font-awesome';

import { useNoticationContext } from 'contexts/notification-context';
import { useStateContext } from 'contexts/state-context';

import useStyles from 'apputil/view-styles';
import { getLoyaltyCard } from 'state/selectors';
import {
  Paper, Typography, Grid,
} from '@material-ui/core';
import InputPassData from './input-pass-data';

const UpdateLoyaltyStamp = ({ passId }) => {
  const styles = useStyles();
  const logger = buildLogger();

  const [loading, setLoading] = useState(false);
  const [fieldError, setFieldError] = useState(false);
  const [formError, setFormError] = useState(false);
  const [loyaltyValue, setLoyaltyValue] = useState(0);
  const [loyalty, setLoyalty] = useState({});

  const noticationContext = useNoticationContext();
  const stateContext = useStateContext();

  const { showNotification, types } = noticationContext;

  useEffect(() => {
    const onLoadLoyaltyCard = async () => {
      setLoading(true);
      const loyaltyData = getLoyaltyCard();
      setLoyalty(loyaltyData);
      setLoading(false);
    };
    onLoadLoyaltyCard();
    // eslint-disable-next-line
  }, []);

  const resetElement = (id) => {
    const element = root.document.getElementById(id);
    if (element) {
      element.value = '';
    }
  };

  const validateLoyalty = () => {
    const { loyaltyAmount } = loyalty;
    if (isNaN(Number(loyaltyValue))) {
      return false;
    }
    const totalLoyalty = (loyaltyAmount + Number(loyaltyValue));
    const maxLoyalty = (requiredLoyaltyAmount + requiredLoyaltyAmount);
    return totalLoyalty < maxLoyalty;
  };

  const saveUpdatedLoyalty = async () => {
    if (fieldError || !loyaltyValue) {
      setFormError(true);
    } else {
      try {
        setLoading(true);
        const { serialNumber } = loyalty;
        if (validateLoyalty()) {
          const getLoyaltyService = await import('services/loyalty-service');
          const { updateLoyaltyStamp } = getLoyaltyService.default({
            stateContext,
            noticationContext,
          });
          const isUpdated = await updateLoyaltyStamp({
            loyalty: loyaltyValue,
            passId,
            serialNumber,
          });
          if (isUpdated) {
            logger.info({ loyalty, message: 'saveUpdatedLoyalty' });
            const loyaltyData = await getLoyaltyCard();
            setLoyalty(loyaltyData);
            showNotification({
              message: `Loyalty updated. ${loyaltyValue} added.`,
              type: types.success,
            });
            resetElement('loyalty-input');
          }
        } else {
          showNotification({
            message: `You can't add that much loyalty. ${(requiredLoyaltyAmount + requiredLoyaltyAmount)} max please.`,
            type: types.warning,
            duration: 5000,
          });
        }
      } catch (err) {
        const trace = await StackTrace.get();
        const { stack = '', message = '' } = err;
        logger.error(new LoggableError({
          data: { stack, caller: 'saveUpdatedLoyalty:onError' },
          message,
          trace,
        }));
      } finally {
        setLoading(false);
      }
    }
  };

  const { loyaltyAmount, requiredLoyaltyAmount } = loyalty;

  return (
    <Paper className={styles.paper} elevation={4}>
      <form autoComplete="off" id="update-loyalty-stamp">
        <Grid spacing={2}
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            className={styles.grid}
          >

          <Grid item xs={12} sm={10} className={styles.topMarginTwo}>
            <Typography
            variant="h5"
            component="p" align="center">
              {`There are ${loyaltyAmount ? `${loyaltyAmount} stamps on this card.  ` : 'There are no Loyalty stamps on this card.  '}${
                requiredLoyaltyAmount - loyaltyAmount === 0
                  ? `${requiredLoyaltyAmount} loyalty stamps are needed for the next reward.`
                  : `${requiredLoyaltyAmount - loyaltyAmount} loyalty stamps needed for next reward.`}`}
              </Typography>
          </Grid>
          </Grid>
          <InputPassData
            inputId="loyalty-input"
            label="Loyalty Stamp Quantity"
            loading={loading}
            onSave={saveUpdatedLoyalty}
            onfieldChange={(value) => {
              setFormError(false);
              setFieldError(isNaN(Number(value)));
              setLoyaltyValue(Number(value));
            }}
            fieldError={fieldError}
            formError={formError}
            fieldMessage={'The amount of "Loyalty stamps" to add'}
            endIcon={LOYALTY_STAMP}
          />
        </form>
      </Paper>
  );
};

UpdateLoyaltyStamp.propTypes = {
  passId: string.isRequired,
};

export default UpdateLoyaltyStamp;
