import React, { useState } from 'react';
import { bool, string, func } from 'prop-types';
import AdornmentIcon from 'components/addmornment-icon';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/pro-solid-svg-icons';

import useStyles from 'apputil/view-styles';

import {
  Grid, Button, TextField, InputAdornment, Hidden, CircularProgress,
} from '@material-ui/core';

const InputPassData = ({
  onSave,
  inputId,
  label,
  loading,
  onfieldChange,
  fieldError,
  formError,
  fieldMessage,
  endIcon,
  disable,
  buttonMessage = 'Update Loyalty',
}) => {
  const styles = useStyles();
  const [isValid, setIsValid] = useState(false);

  const saveAction = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    onSave();
  };

  const onChange = (event) => {
    const { target: { value = '' } } = event;
    value.trim();
    if (value) {
      setIsValid(!!value);
      onfieldChange(value);
    } else {
      setIsValid(false);
    }
  };

  return (
    <Grid spacing={2}
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="stretch"
        className={styles.grid}
      >
      <Hidden xsDown>
        <Grid item xs={1}/>
      </Hidden>
      <Grid item xs={12} sm={10}>
        <TextField
           required
           id={inputId}
           label={label}
           fullWidth
           type="tel"
           error={fieldError || formError}
           helperText={
             fieldError || formError
               ? `${formError ? 'This field is required, value must be a number, greater than 0' : 'Must be a number, greater than 0'}`
               : fieldMessage
           }
           margin="normal"
           variant="outlined"
           onChange={onChange}
           disabled={disable}
           InputProps={{
             endAdornment: <InputAdornment position="end">{
               <AdornmentIcon
                 error={fieldError || formError}
                 valid={isValid}
                 type={endIcon}
                 />
             }</InputAdornment>,
           }}
         />
      </Grid>
      <Hidden xsDown>
        <Grid item xs={1}/>
        <Grid item xs={1}/>
      </Hidden>
        <Grid item xs={12} sm={10}>
           <Hidden smUp>
             <Button
               variant="contained"
               color="primary"
               className={styles.formbutton}
               onClick={saveAction}
               type="submit"
               aria-label="update loyalty or redeem"
               fullWidth
               disabled={loading || disable}
               endIcon={loading
                 ? <CircularProgress className={styles.submitLoading}/>
                 : (
                   <FontAwesomeIcon icon={faSave} className={styles.submitIcon}/>
                 )
               }
               >
              {buttonMessage}
            </Button>
           </Hidden>
           <Hidden xsDown>
             <Button
               variant="contained"
               color="primary"
               className={styles.formbutton}
               onClick={saveAction}
               type="submit"
               fullWidth
               aria-label="update loyalty or redeem"
               disabled={loading || disable}
               endIcon={loading
                 ? <CircularProgress className={styles.submitLoading}/>
                 : (
                   <FontAwesomeIcon icon={faSave} className={styles.submitIcon}/>
                 )
               }
               >
              {buttonMessage}
            </Button>
           </Hidden>
        </Grid>
      </Grid>
  );
};

InputPassData.propTypes = {
  inputId: string.isRequired,
  label: string.isRequired,
  loading: bool.isRequired,
  onSave: func.isRequired,
  onfieldChange: func.isRequired,
  fieldError: bool.isRequired,
  formError: bool.isRequired,
  fieldMessage: string.isRequired,
  endIcon: string.isRequired,
  disable: bool,
  buttonMessage: string,
};

InputPassData.defaultProps = { disableSave: false };

export default InputPassData;
